* {
  font-family: 'Open Sans', sans-serif;
}

html, body {
  margin: 0;
  background: #f2f2f2;
}

.fullscreen-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

a.button {
  text-decoration: none;
}
.button {
  color: #333;
  background: #fff;
  border: 2px #333 solid;
  border-radius: 10px;
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  transition: all ease .5s;
  cursor: pointer;

  &.inverted, &:hover {
    color: #fff;
    background: #333;
  }
  &.inverted:hover {
    color: #333;
    background: #fff;
  }
}

.label {
  color: #333;
  display: block;
  padding-left: .25rem;
  font-size: .8rem;
  font-weight: 600;
}
.input {
  color: #333;
  border: none;
  border-bottom: 2px #d9d9d9 solid;
  padding: .5rem .25rem;
  font-size: 1.25rem;
}

#nocode-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

#nocode {
  background: #fff;
  width: 500px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  margin: 3rem 1rem;
  border-radius: 10px;
  padding: 1.5rem;

  .label {
    color: #333;
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
  }
  & > *:not(:last-child) {
    margin-bottom: 2rem;
  }
}
