.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

#plan-nav-wrapper {
  background: #fff;
  position: fixed;
  width: 100vw;
}

#plan-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 0;
  font-size: 1.25rem;

  button {
    color: #333;
    display: flex;
    background: #fff;
    align-items: center;
    border: none;
    cursor: pointer;
  }

  & > * {
    margin: 0 .75rem;
  }
}

#plan {
  max-width: 600px;
  margin: 0 auto;
  margin-top: 4.25rem;
  padding: 2rem 1rem;
  overflow-y: scroll;

  a {
    text-decoration: none;
    color: inherit;
  }

  .day {
    background: #fff;
    margin-bottom: 1.5rem;
    padding: .75rem 1rem;
    border: 1px white solid;
    border-radius: 10px;
    &.drag-over {
      border: 1px #333 dashed;
      .date {
        color: #333;
      }
    }

    .date {
      color: #aaa;
      display: block;
      font-weight: 600;
      font-size: .9rem;
      text-transform: uppercase;
    }
  }
}

#new-entry {
  position: fixed;
  right: 1.5rem;
  bottom: 1.5rem;
  height: 3.5rem;
  width: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: #333;
  border: 2px #f2f2f2 solid;
  border-radius: 50%;
  font-size: 2rem;
  text-decoration: none;
  cursor: pointer;
  z-index: 10;
}
