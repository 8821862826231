.recipe-card {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px #d9d9d9 solid;
  background: #fff;
  cursor: pointer;
  color: inherit;
  text-decoration: none;

  img {
    height: 250px;
    width: 100%;
    object-fit: cover;
    @media only screen and (max-width: 600px) {
      height: 200px;
    }
  }

  .date {
    position: absolute;
    right: 0;
    top: .75rem;
    display: flex;
  
    span {
      padding: .25rem .75rem;
      background: #2b2b60;
      color: white;
      font-size: 1.2rem;
    }
    svg {
      fill: #2b2b60;
      max-height: 35px;
      max-width: 26px;
    }
  }
  
  h2 {
    flex-grow: 1;
    margin: 0;
    padding: 1.5rem;
    font-size: 1.5rem;
    line-height: 1.15;
    font-weight: 300;
    color: #333;
    word-wrap: break-word;
  }
  
  h2 span {
    padding-right: 2rem;
    &:after {
      content: "v";
      color: #fff;
      position: absolute;
      display: inline-flex;
      align-items: center;
      width: 1rem;
      height: 1rem;
      margin-top: .15rem;
      margin-left: .5rem;
      border-radius: 50%;
      font-size: 1.5rem;
      font-weight: 900;
      text-transform: uppercase;
    }
  }

  footer {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #f2f2f2;
    padding: 1rem 0;
    margin: 0 1rem;
    span {
      margin: .25rem .5rem;
      font-family: 'Merriweather', serif;
      font-size: 12px;
      font-weight: 900;
      text-transform: uppercase;
    }
  }
}

.vegetarian::after {
  background: #333;
}

.vegan::after {
  background: #4dbf40;
}
