#top-bar-container {
  background: #fff;
  width: 100%;
  z-index: 10;
}

#top-bar {
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 1rem;
  padding-bottom: 0;
  #back  {
    padding: 0;
    .icon {
      margin-right: .5rem;
      font-size: 10pt;
    }
  }
  #add-to-plan {
    span {
      margin-right: .5rem;
    }
  }
  a, button {
    color: #777;
    background: inherit;
    position: relative;
    display: inline-flex;
    align-items: center;
    border: none;
    font-family: 'Open Sans', sans-serif;
    font-size: .75rem;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
    transition: all .5s ease;
    cursor: pointer;
    &:hover {
      color: #333;
    }
  }
}

@keyframes hovericon {
  from {
    left: 0;
  }
  to {
    left: -1rem;
  }
}

.recipe-container {
  width: 100%;
  background: #fff;
}

.recipe {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
}

.recipe-title {
  margin: 2rem 0;
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: .5px;
  text-transform: uppercase;
  font-family: 'Merriweather', serif;
}

.recipe-img {
  width: 100%;
  max-height: 500px;
  object-fit: cover;
}

.recipe-info {
  margin: 0;
  margin-top: 2rem;
  font-size: 1.2rem;
  display: grid;
  grid-template-columns: 2fr 3fr;
}

.duration {
  line-height: 2;
  font-family: 'Merriweather', serif;
}

.categories {
  margin-top: .2rem;
}

.categories a {
  display: inline-block;
  margin-right: 1rem;
  margin-bottom: 1rem;
  border-radius: 10px;
  padding: .5rem 1rem;
  color: unset;
  background: #f2f2f2;
  line-height: 1.2;
  font-size: 1rem;
  text-decoration: none;
  &:hover {
    background: #e9e9e9;
  }
}

.recipe-main {
  display: grid;
  grid-template-columns: 2fr 3fr;
  margin-bottom: 1.5rem;
}

.recipe-main h2 {
  font-weight: 400;
  margin-bottom: 0.5rem;
  font-family: 'Merriweather', serif;
  & > * {
    font-family: 'Merriweather', serif;
  }
}

.border-bottom {
  width: 66.66%;
  border-bottom: 1px solid #f2f2f2;
}

.servings-header {
  display: flex;
  justify-content: space-between;
  width: 66.66%;
  margin-bottom: 0;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: .5rem;
}

.servings-control button {
  border: none;
  border-radius: 100%;
  font-size: 1rem;
  width: 2rem;
  height: 2rem;
  padding: .25rem;
  margin: 0 .75rem;
  background: #fff;
  cursor: pointer;
}

.servings-control button:hover {
  background: #f2f2f2;
}

.servings-control > * {
  font-family: 'Merriweather', sans-serif;
}

ul {
  padding: 0;
  padding-right: 1rem;
}

ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  padding: .66rem 0;
}

button.cart {
  color: #666;
  background: #fff;
  display: flex;
  margin-bottom: -.2rem;
  border: none;
  padding-right: 1rem;
  font-size: 1.3rem;
  cursor: pointer;
}

button.cart:hover {
  color: #000;
}

.description {
  padding: .25rem 0;
  line-height: 1.6;
}

#buttons {
  display: flex;
  flex-wrap: wrap;
  
  .button {
    margin-right: 1rem;
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: 1200px) {
  .recipe-main, .recipe-info {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 710px) {
  ul {
    padding-right: 0;
  }
  .recipe-title {
    font-size: 1.5rem;
  }
  .recipe-main, .recipe-info {
    grid-gap: 1rem;
    grid-template-columns: 1fr;
  }
  .recipe-info {
    margin-top: 1rem;
    margin-bottom: 0;
  }
  .recipe-img {
    max-height: 35vh;
  }
}

@media only screen and (min-width: 711px) {
  button.cart {
    transition: opacity .25s ease;
    opacity: 0;
  }
  li:hover button.cart {
    opacity: 1;
  }
}

@media only screen and (max-width: 900px) {
  .servings-header {
    width: 100%;
  }
}
