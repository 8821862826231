#recipe-list-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

#recipe-list {
  max-width: 1200px;
  margin: 1.5rem auto;
  padding: 0 1rem;
  padding-bottom: 5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1.5rem;
  row-gap: 1.5rem;
  @media only screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }
  @media only screen and (max-width: 600px) {
    grid-template-columns: minmax(0, 1fr);
  }
}

#new-recipe {
  position: fixed;
  right: 1.5rem;
  bottom: 1.5rem;
  height: 3.5rem;
  width: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: #333;
  border: 2px #f2f2f2 solid;
  border-radius: 50%;
  font-size: 2rem;
  text-decoration: none;
  cursor: pointer;
  z-index: 10;
}
