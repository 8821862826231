.Toastify__toast-container {
  z-index: 9999;
  width: 320px;
  box-sizing: border-box;
  color: #fff;
  padding: 4px;
  position: fixed;
  -webkit-transform: translateZ(9999px);
}

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    margin: 0;
    padding: 0;
    left: 0;
  }

  .Toastify__toast-container--top-center, .Toastify__toast-container--top-left, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    left: auto;
    right: 0;
  }
}

.Toastify__toast {
  min-height: 64px;
  box-sizing: border-box;
  max-height: 800px;
  cursor: pointer;
  direction: ltr;
  border-radius: 1px;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 8px;
  font-family: sans-serif;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 10px #0000001a, 0 2px 15px #0000000d;
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast--dark {
  color: #fff;
  background: #121212;
}

.Toastify__toast--default {
  color: #aaa;
  background: #fff;
}

.Toastify__toast--info {
  background: #3498db;
}

.Toastify__toast--success {
  background: #07bc0c;
}

.Toastify__toast--warning {
  background: #f1c40f;
}

.Toastify__toast--error {
  background: #e74c3c;
}

.Toastify__toast-body {
  flex: auto;
  margin: auto 0;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    margin-bottom: 0;
  }
}

.Toastify__close-button {
  color: #fff;
  cursor: pointer;
  opacity: .7;
  background: none;
  border: none;
  outline: none;
  align-self: flex-start;
  padding: 0;
  transition: all .3s;
}

.Toastify__close-button--default {
  color: #000;
  opacity: .3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}

.Toastify__close-button:focus, .Toastify__close-button:hover {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  to {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  width: 100%;
  height: 5px;
  z-index: 9999;
  opacity: .7;
  transform-origin: 0;
  background-color: #ffffffb3;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--animated {
  animation: linear forwards Toastify__trackProgress;
}

.Toastify__progress-bar--controlled {
  transition: transform .2s;
}

.Toastify__progress-bar--rtl {
  transform-origin: 100%;
  left: auto;
  right: 0;
}

.Toastify__progress-bar--default {
  background: linear-gradient(90deg, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
}

.Toastify__progress-bar--dark {
  background: #bb86fc;
}

@keyframes Toastify__bounceInRight {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInLeft {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInUp {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translateZ(0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--bottom-left, .Toastify__bounce-enter--top-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--bottom-right, .Toastify__bounce-enter--top-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--bottom-left, .Toastify__bounce-exit--top-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--bottom-right, .Toastify__bounce-exit--top-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotateX(90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotateX(-20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotateX(10deg);
  }

  80% {
    transform: perspective(400px)rotateX(-5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  0% {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotateX(-20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotateX(90deg);
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  0% {
    visibility: visible;
    transform: translate3d(110%, 0, 0);
  }

  to {
    transform: translateZ(0);
  }
}

@keyframes Toastify__slideInLeft {
  0% {
    visibility: visible;
    transform: translate3d(-110%, 0, 0);
  }

  to {
    transform: translateZ(0);
  }
}

@keyframes Toastify__slideInUp {
  0% {
    visibility: visible;
    transform: translate3d(0, 110%, 0);
  }

  to {
    transform: translateZ(0);
  }
}

@keyframes Toastify__slideInDown {
  0% {
    visibility: visible;
    transform: translate3d(0, -110%, 0);
  }

  to {
    transform: translateZ(0);
  }
}

@keyframes Toastify__slideOutRight {
  0% {
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}

@keyframes Toastify__slideOutLeft {
  0% {
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}

@keyframes Toastify__slideOutDown {
  0% {
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  0% {
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--bottom-left, .Toastify__slide-enter--top-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--bottom-right, .Toastify__slide-enter--top-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--bottom-left, .Toastify__slide-exit--top-left {
  animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--bottom-right, .Toastify__slide-exit--top-right {
  animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

#login {
  width: 500px;
  max-width: 100%;
  background: #fff;
  border-radius: 10px;
  flex-direction: column;
  margin: 0 1rem;
  padding: 3rem;
  display: flex;
}

#login input {
  color: #333;
  border: none;
  border-bottom: 2px solid #d9d9d9;
  margin-bottom: 1rem;
  padding: .5rem .25rem;
  font-size: 1.25rem;
}

.icon {
  font-size: 16pt;
  display: inline-block;
}

@font-face {
  font-family: icomoon;
  src: url("icomoon.a1c62ef3.ttf") format("truetype"), url("icomoon.e8654b89.woff") format("woff"), url("icomoon.2831f15a.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  speak: none;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-family: icomoon !important;
}

.icon-sort:before {
  content: "";
}

.icon-menu:before {
  content: "";
}

.icon-create:before {
  content: "";
}

.icon-add_shopping_cart:before {
  content: "";
}

.icon-share:before {
  content: "";
}

.icon-settings:before {
  content: "";
}

.icon-wb_sunny:before {
  content: "";
}

.icon-shopping_cart:before {
  content: "";
}

.icon-add:before {
  content: "";
}

.icon-search:before {
  content: "";
}

.icon-arrow-left2:before {
  content: "";
}

nav {
  width: 100%;
  z-index: 10;
  background: #fff;
  border-bottom: 2px solid #f2f2f2;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

#nav-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  position: relative;
}

#nav-wrapper > a {
  padding-right: 3rem;
}

#nav-wrapper > a, #menu #current-page {
  color: #666;
  text-align: center;
  text-transform: uppercase;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  transition: all .3s;
}

#menu {
  background: #fff;
  border: none;
  display: none;
  position: relative;
}

#menu .menu-button {
  z-index: 10;
  padding-left: 0;
  position: absolute;
  top: 1rem;
  left: 1rem;
}

#menu #current-page {
  color: #000;
}

#menu #current-page.hidden {
  display: none;
}

nav button, nav .nav-button {
  background: inherit;
  cursor: pointer;
  border: none;
  font-size: 1.3rem;
  line-height: 1;
  transition: all .3s;
}

nav .nav-button:not(:last-child) {
  margin-right: 1rem;
}

nav button:hover {
  color: #000;
}

nav .icon {
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  display: inline-block;
}

.right-buttons {
  line-height: 1;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.right-buttons .nav-button {
  color: #666;
  padding: 0;
  transition: all .3s;
}

.right-buttons .nav-button.active {
  color: #ef6461;
}

@media (hover: hover) {
  .right-buttons .nav-button:hover {
    color: #ef6461;
  }

  .right-buttons .nav-button.active:hover {
    color: #666;
  }
}

#nav-wrapper.open {
  display: grid;
}

#nav-wrapper > a:hover, #nav-wrapper > a.active {
  color: #000;
}

@media only screen and (max-width: 600px) {
  #nav-wrapper {
    display: none;
  }

  #nav-wrapper > a {
    padding-right: 0;
  }

  #menu {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

#searchbar {
  width: 100%;
  background: #fff;
  justify-content: center;
  display: flex;
}

#search-input {
  width: 100%;
  max-width: 1200px;
  border-bottom: 1px solid #f2f2f2;
  align-items: center;
  padding: 1.5rem 0;
  display: flex;
}

.icon.icon-search {
  color: #b2b2b2;
  margin-right: .75rem;
  font-size: 1.75rem;
}

#searchbar input {
  border: none;
  flex-grow: 1;
  font-size: 1.25rem;
}

input[type="search"] {
  -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::placeholder {
  color: #b2b2b2;
}

@media only screen and (max-width: 1200px) {
  #search-input {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .icon.icon-search {
    margin-right: .5rem;
  }
}

.recipe-card {
  cursor: pointer;
  color: inherit;
  background: #fff;
  border: 1px solid #d9d9d9;
  flex-direction: column;
  text-decoration: none;
  display: flex;
  position: relative;
}

.recipe-card img {
  height: 250px;
  width: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 600px) {
  .recipe-card img {
    height: 200px;
  }
}

.recipe-card .date {
  display: flex;
  position: absolute;
  top: .75rem;
  right: 0;
}

.recipe-card .date span {
  color: #fff;
  background: #2b2b60;
  padding: .25rem .75rem;
  font-size: 1.2rem;
}

.recipe-card .date svg {
  fill: #2b2b60;
  max-height: 35px;
  max-width: 26px;
}

.recipe-card h2 {
  color: #333;
  word-wrap: break-word;
  flex-grow: 1;
  margin: 0;
  padding: 1.5rem;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.15;
}

.recipe-card h2 span {
  padding-right: 2rem;
}

.recipe-card h2 span:after {
  content: "v";
  color: #fff;
  width: 1rem;
  height: 1rem;
  text-transform: uppercase;
  border-radius: 50%;
  align-items: center;
  margin-top: .15rem;
  margin-left: .5rem;
  font-size: 1.5rem;
  font-weight: 900;
  display: inline-flex;
  position: absolute;
}

.recipe-card footer {
  border-top: 1px solid #f2f2f2;
  flex-wrap: wrap;
  margin: 0 1rem;
  padding: 1rem 0;
  display: flex;
}

.recipe-card footer span {
  text-transform: uppercase;
  margin: .25rem .5rem;
  font-family: Merriweather, serif;
  font-size: 12px;
  font-weight: 900;
}

.vegetarian:after {
  background: #333;
}

.vegan:after {
  background: #4dbf40;
}

#recipe-list-wrapper {
  width: 100%;
  justify-content: center;
  display: flex;
}

#recipe-list {
  max-width: 1200px;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.5rem;
  margin: 1.5rem auto;
  padding: 0 1rem 5rem;
  display: grid;
}

@media only screen and (max-width: 1200px) {
  #recipe-list {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 600px) {
  #recipe-list {
    grid-template-columns: minmax(0, 1fr);
  }
}

#new-recipe {
  height: 3.5rem;
  width: 3.5rem;
  color: #333;
  cursor: pointer;
  z-index: 10;
  background: #fff;
  border: 2px solid #f2f2f2;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  text-decoration: none;
  display: flex;
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
}

.toast-undo {
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  font-size: 1rem;
  display: flex;
}

.toast-undo button {
  color: #b03b3b;
  background: inherit;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  font-size: 1rem;
  font-weight: 600;
}

#top-bar-container {
  width: 100%;
  z-index: 10;
  background: #fff;
}

#top-bar {
  max-width: 1000px;
  justify-content: space-between;
  margin: 0 auto;
  padding: 1rem 1rem 0;
  display: flex;
}

#top-bar #back {
  padding: 0;
}

#top-bar #back .icon {
  margin-right: .5rem;
  font-size: 10pt;
}

#top-bar #add-to-plan span {
  margin-right: .5rem;
}

#top-bar a, #top-bar button {
  color: #777;
  background: inherit;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  align-items: center;
  font-family: Open Sans, sans-serif;
  font-size: .75rem;
  font-weight: 600;
  text-decoration: none;
  transition: all .5s;
  display: inline-flex;
  position: relative;
}

#top-bar a:hover, #top-bar button:hover {
  color: #333;
}

@keyframes hovericon {
  from {
    left: 0;
  }

  to {
    left: -1rem;
  }
}

.recipe-container {
  width: 100%;
  background: #fff;
}

.recipe {
  max-width: 1000px;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
}

.recipe-title {
  letter-spacing: .5px;
  text-transform: uppercase;
  margin: 2rem 0;
  font-family: Merriweather, serif;
  font-size: 2rem;
  font-weight: 400;
}

.recipe-img {
  width: 100%;
  max-height: 500px;
  object-fit: cover;
}

.recipe-info {
  grid-template-columns: 2fr 3fr;
  margin: 2rem 0 0;
  font-size: 1.2rem;
  display: grid;
}

.duration {
  font-family: Merriweather, serif;
  line-height: 2;
}

.categories {
  margin-top: .2rem;
}

.categories a {
  color: unset;
  background: #f2f2f2;
  border-radius: 10px;
  margin-bottom: 1rem;
  margin-right: 1rem;
  padding: .5rem 1rem;
  font-size: 1rem;
  line-height: 1.2;
  text-decoration: none;
  display: inline-block;
}

.categories a:hover {
  background: #e9e9e9;
}

.recipe-main {
  grid-template-columns: 2fr 3fr;
  margin-bottom: 1.5rem;
  display: grid;
}

.recipe-main h2 {
  margin-bottom: .5rem;
  font-family: Merriweather, serif;
  font-weight: 400;
}

.recipe-main h2 > * {
  font-family: Merriweather, serif;
}

.border-bottom {
  width: 66.66%;
  border-bottom: 1px solid #f2f2f2;
}

.servings-header {
  width: 66.66%;
  border-bottom: 1px solid #f2f2f2;
  justify-content: space-between;
  margin-bottom: 0;
  padding-bottom: .5rem;
  display: flex;
}

.servings-control button {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  background: #fff;
  border: none;
  border-radius: 100%;
  margin: 0 .75rem;
  padding: .25rem;
  font-size: 1rem;
}

.servings-control button:hover {
  background: #f2f2f2;
}

.servings-control > * {
  font-family: Merriweather, sans-serif;
}

ul {
  padding: 0 1rem 0 0;
}

ul li {
  justify-content: space-between;
  align-items: center;
  padding: .66rem 0;
  list-style-type: none;
  display: flex;
}

button.cart {
  color: #666;
  cursor: pointer;
  background: #fff;
  border: none;
  margin-bottom: -.2rem;
  padding-right: 1rem;
  font-size: 1.3rem;
  display: flex;
}

button.cart:hover {
  color: #000;
}

.description {
  padding: .25rem 0;
  line-height: 1.6;
}

#buttons {
  flex-wrap: wrap;
  display: flex;
}

#buttons .button {
  margin-bottom: 2rem;
  margin-right: 1rem;
}

@media only screen and (max-width: 1200px) {
  .recipe-main, .recipe-info {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 710px) {
  ul {
    padding-right: 0;
  }

  .recipe-title {
    font-size: 1.5rem;
  }

  .recipe-main, .recipe-info {
    grid-gap: 1rem;
    grid-template-columns: 1fr;
  }

  .recipe-info {
    margin-top: 1rem;
    margin-bottom: 0;
  }

  .recipe-img {
    max-height: 35vh;
  }
}

@media only screen and (min-width: 711px) {
  button.cart {
    opacity: 0;
    transition: opacity .25s;
  }

  li:hover button.cart {
    opacity: 1;
  }
}

@media only screen and (max-width: 900px) {
  .servings-header {
    width: 100%;
  }
}

#loader {
  color: #333;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  display: flex;
}

.lds-dual-ring {
  width: 64px;
  height: 64px;
  display: inline-block;
}

.lds-dual-ring:after {
  content: " ";
  width: 46px;
  height: 46px;
  border: 5px solid #666;
  border-color: #666 #0000;
  border-radius: 50%;
  margin: 1px;
  animation: 1.2s linear infinite lds-dual-ring;
  display: block;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.message {
  white-space: pre-wrap;
  text-align: center;
  margin-top: 2rem;
}

#new-item-wrapper {
  background: #fff;
  justify-content: center;
  display: flex;
}

#new-item-wrapper.sticky {
  z-index: 10;
  border-bottom: 2px solid #f2f2f2;
  position: -webkit-sticky;
  position: sticky;
  top: 56.5px;
}

#new-item {
  width: 100%;
  max-width: 1200px;
  justify-content: center;
  display: flex;
}

#new-item input {
  border: none;
  flex-grow: 1;
  padding: 1.5rem 0;
  font-size: 1.25rem;
}

#new-item ::placeholder {
  color: #b2b2b2;
}

#new-item .select {
  align-items: center;
  gap: .75rem;
  margin: 1rem 0 1rem 1rem;
  display: flex;
  position: relative;
}

#new-item .select label {
  font-weight: bold;
}

#new-item .select select {
  text-align: right;
  color: #333;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #fff;
  border: none;
  padding-right: 3rem;
  font-size: 1rem;
}

#new-item .select:after {
  z-index: 4;
  content: " ";
  height: .625em;
  pointer-events: none;
  cursor: pointer;
  transform-origin: center;
  width: .625em;
  border-top: 0;
  border-bottom: 2px solid #333;
  border-left: 2px solid #333;
  border-right: 0;
  margin-top: -.55em;
  display: block;
  position: absolute;
  top: 50%;
  right: 1.125em;
  transform: rotate(-45deg);
}

#list-wrapper {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

#list-container {
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
  margin: 0 auto;
}

#list {
  margin-bottom: 5rem;
  padding-top: .5rem;
}

#list .chosen {
  background: #fafafa;
}

#list .item-wrapper {
  background: #fff;
  border-radius: 10px;
  margin: 1rem 0;
  padding: 1rem;
}

#list .item {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

#list .item button {
  color: #aaa;
  background: inherit;
  cursor: pointer;
  border: none;
  font-size: 1rem;
  display: inline-flex;
}

#list .ctg-wrapper:not(:first-child) .ctg-label {
  margin-top: 1.5rem;
}

#list .ctg-label {
  text-transform: uppercase;
  margin-top: 1rem;
  font-weight: 700;
}

.profile-button {
  width: 100%;
  cursor: pointer;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 1.5rem auto;
  padding: .5rem 1rem;
  font-size: 1rem;
  display: block;
}

#remove-all {
  margin-bottom: 3rem;
}

@media only screen and (max-width: 1200px) {
  #new-item {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  #list-container {
    padding: 0 1rem;
  }
}

@media only screen and (max-width: 600px) {
  #new-item {
    flex-direction: column;
  }

  #new-item .select {
    border: none;
    border-top: 2px solid #f2f2f2;
    border-radius: 0;
    margin: 0;
    padding: 1rem 0;
  }

  #new-item .select select {
    text-align: left;
    width: 100%;
  }
}

#list .input-container {
  width: 100%;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  box-sizing: border-box;
  padding-left: 2.2rem;
  font-size: 1rem;
  font-weight: 400;
  display: inline-block;
  position: relative;
}

#list .input-container input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  position: absolute;
}

#list .checkmark {
  height: 25px;
  width: 25px;
  background-color: #f2f2f2;
  position: absolute;
  top: 0;
  left: 0;
}

#list .input-container:hover input ~ .checkmark {
  background-color: #d9d9d9;
}

#list .checkmark:after {
  content: "";
  display: none;
  position: absolute;
}

#list .input-container .checkmark.box:after {
  width: 5px;
  height: 10px;
  border: 3px solid #fff;
  border-width: 0 3px 3px 0;
  top: 5px;
  left: 9px;
  transform: rotate(45deg);
}

.plan-entry {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.plan-entry .controls {
  color: #aaa;
  font-size: 1.3rem;
}

.plan-entry .meta {
  color: #333;
  text-transform: uppercase;
  padding-top: .5rem;
  font-size: .9rem;
  font-weight: 600;
}

.plan-entry .title {
  color: #333;
  max-width: 80vw;
  padding-top: .25rem;
  font-size: 1.1rem;
}

.plan-entry a.title:hover {
  text-decoration: underline;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

#plan-nav-wrapper {
  width: 100vw;
  background: #fff;
  position: fixed;
}

#plan-nav {
  justify-content: center;
  align-items: center;
  padding: 1.5rem 0;
  font-size: 1.25rem;
  display: flex;
}

#plan-nav button {
  color: #333;
  cursor: pointer;
  background: #fff;
  border: none;
  align-items: center;
  display: flex;
}

#plan-nav > * {
  margin: 0 .75rem;
}

#plan {
  max-width: 600px;
  margin: 4.25rem auto 0;
  padding: 2rem 1rem;
  overflow-y: scroll;
}

#plan a {
  color: inherit;
  text-decoration: none;
}

#plan .day {
  background: #fff;
  border: 1px solid #fff;
  border-radius: 10px;
  margin-bottom: 1.5rem;
  padding: .75rem 1rem;
}

#plan .day.drag-over {
  border: 1px dashed #333;
}

#plan .day.drag-over .date {
  color: #333;
}

#plan .day .date {
  color: #aaa;
  text-transform: uppercase;
  font-size: .9rem;
  font-weight: 600;
  display: block;
}

#new-entry {
  height: 3.5rem;
  width: 3.5rem;
  color: #333;
  cursor: pointer;
  z-index: 10;
  background: #fff;
  border: 2px solid #f2f2f2;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  text-decoration: none;
  display: flex;
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
}

#plan-form-container {
  min-height: 100vh;
  background: #fff;
}

#plan-form {
  max-width: 800px;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 1.5rem;
  display: flex;
}

#plan-form > * {
  margin-bottom: 1.5rem;
}

#plan-form label {
  color: #333;
  padding-left: .25rem;
  font-size: .8rem;
  font-weight: 600;
  display: block;
}

#plan-form input {
  color: #333;
  width: 100%;
  border: none;
  border-bottom: 2px solid #d9d9d9;
  padding: .5rem 0 .5rem .25rem;
  font-size: 1.25rem;
}

#plan-form input:disabled {
  background: #f2f2f2;
}

#plan-form .half {
  grid-gap: 4rem;
  grid-template-columns: 1fr 1fr;
  display: grid;
}

#plan-form #recipe-search button {
  margin-top: 1.5rem;
}

#plan-form #search-results {
  width: 100%;
  border-left: 2px solid #f2f2f2;
  border-right: 2px solid #f2f2f2;
}

#plan-form #search-results div {
  cursor: pointer;
  border-bottom: 2px solid #f2f2f2;
  padding: .5rem .25rem;
  font-size: 1.2rem;
}

#plan-form #search-results div:hover {
  background: #f2f2f2;
}

#plan-form #buttons {
  justify-content: space-between;
  display: flex;
}

#plan-form #delete {
  color: #fff;
  background: #c9342c;
  border-color: #c9342c;
}

#plan-form #delete:hover {
  color: #c9342c;
  background: #fff;
}

#profile-categories {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.list-profiles {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.list-profiles .profile {
  align-items: center;
  margin: .5rem 0;
  font-size: 1.1rem;
  display: flex;
}

.list-profiles .profile button {
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  margin-left: 1rem;
}

.list-profiles .profile button .delete-profile {
  font-size: 1.25rem;
  line-height: 1rem;
}

.list-profiles > button {
  align-self: flex-start;
  margin-top: 1rem;
}

.profile-category {
  cursor: pointer;
  border: 1px dotted #999;
  border-radius: 5px;
  margin: .5rem 0;
  padding: .5rem 1rem;
}

.modal-input {
  color: #333;
  border: none;
  border-bottom: 2px solid #d9d9d9;
  margin-bottom: 1rem;
  padding: .5rem .25rem;
  font-size: 1.25rem;
}

#settings-container {
  background: #fff;
}

#settings {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.5rem 1.5rem 3rem;
}

#settings h2 {
  margin-top: 0;
  margin-bottom: 2rem;
}

#settings > * {
  max-width: 800px;
  flex-direction: column;
  display: flex;
}

#settings label {
  color: #333;
  margin-bottom: .5rem;
  font-size: 1rem;
  font-weight: 600;
}

#settings input {
  color: #333;
  border: none;
  border-bottom: 2px solid #d9d9d9;
  margin-bottom: 1rem;
  padding: .5rem .25rem;
  font-size: 1.25rem;
}

#settings select {
  color: #333;
  background: #fff;
  border: none;
  border-bottom: 2px solid #d9d9d9;
  margin-bottom: 1rem;
  padding: .5rem .25rem;
  font-size: 1.25rem;
}

* {
  font-family: Open Sans, sans-serif;
}

html, body {
  background: #f2f2f2;
  margin: 0;
}

.fullscreen-container {
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
}

a.button {
  text-decoration: none;
}

.button {
  color: #333;
  text-transform: uppercase;
  cursor: pointer;
  background: #fff;
  border: 2px solid #333;
  border-radius: 10px;
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  transition: all .5s;
}

.button.inverted, .button:hover {
  color: #fff;
  background: #333;
}

.button.inverted:hover {
  color: #333;
  background: #fff;
}

.label {
  color: #333;
  padding-left: .25rem;
  font-size: .8rem;
  font-weight: 600;
  display: block;
}

.input {
  color: #333;
  border: none;
  border-bottom: 2px solid #d9d9d9;
  padding: .5rem .25rem;
  font-size: 1.25rem;
}

#nocode-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
}

#nocode {
  width: 500px;
  max-width: 100%;
  background: #fff;
  border-radius: 10px;
  flex-direction: column;
  margin: 3rem 1rem;
  padding: 1.5rem;
  display: flex;
}

#nocode .label {
  color: #333;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
}

#nocode > :not(:last-child) {
  margin-bottom: 2rem;
}

/*# sourceMappingURL=index.170baf54.css.map */
