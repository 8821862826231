#login {
  background: #fff;
  padding: 3rem;
  border-radius: 10px;
  width: 500px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 1rem;

  input {
    color: #333;
    margin-bottom: 1rem;
    border: none;
    border-bottom: 2px #d9d9d9 solid;
    padding: .5rem .25rem;
    font-size: 1.25rem;
  }
}
