#settings-container {
  background: #fff;
}
#settings {
  margin: 0 auto;
  max-width: 1200px;
  padding: 1.5rem;
  padding-bottom: 3rem;

  h2 {
    margin-top: 0;
    margin-bottom: 2rem;
  }
  & > * {
    display: flex;
    flex-direction: column;
    max-width: 800px;
  }
  label {
    color: #333;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
  }
  input {
    color: #333;
    margin-bottom: 1rem;
    border: none;
    border-bottom: 2px #d9d9d9 solid;
    padding: .5rem .25rem;
    font-size: 1.25rem;
  }
  select {
    color: #333;
    margin-bottom: 1rem;
    background: #fff;
    border: none;
    border-bottom: 2px #d9d9d9 solid;
    padding: .5rem .25rem;
    font-size: 1.25rem;
  }
}