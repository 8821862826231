#new-item-wrapper {
  display: flex;
  justify-content: center;
  background: #fff;
  &.sticky {
    position: sticky;
    top: 56.5px;
    z-index: 10;
    border-bottom: 2px #f2f2f2 solid;
  }
}
#new-item {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  input {
    flex-grow: 1;
    padding: 1.5rem 0;
    font-size: 1.25rem;
    border: none;
  }
  ::placeholder {
    color: #b2b2b2;
  }
  .select {
    position: relative;
    margin: 1rem 1rem;
    margin-right: 0;
    display: flex;
    align-items: center;
    gap: .75rem;
    label {
      font-weight: bold;
    }
    select {
      padding-right: 3rem;
      text-align: right;
      color: #333;
      background: #fff;
      font-size: 1rem;
      border: none;
      -moz-appearance: none;
      -webkit-appearance: none;
    }
    &::after {
      border: 2px solid #333;
      right: 1.125em;
      z-index: 4;
      border-right: 0;
      border-top: 0;
      content: " ";
      display: block;
      height: .625em;
      margin-top: -.55em;
      pointer-events: none;
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: rotate(-45deg);
      transform-origin: center;
      width: .625em;
    }
  }
}

#list-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#list-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
}

#list {
  padding-top: .5rem;
  margin-bottom: 5rem;
  .chosen {
    background: #fafafa;
  }
  .item-wrapper {
    background: #fff;
    margin: 1rem 0;
    border-radius: 10px;
    padding: 1rem;
  }
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      color: #aaa;
      background: inherit;
      display: inline-flex;
      border: none;
      font-size: 1rem;
      cursor: pointer;
    }
  }
  .ctg-wrapper:not(:first-child) .ctg-label {
    margin-top: 1.5rem
  }
  .ctg-label {
    margin-top: 1rem;
    text-transform: uppercase;
    font-weight: 700;
  }
}

.profile-button {
  display: block;
  margin: 1.5rem auto;
  font-size: 1rem;
  background: #fff;
  border: 1px #ddd solid;
  border-radius: 10px;
  padding: .5rem 1rem;
  width: 100%;
  cursor: pointer;
}

#remove-all {
  margin-bottom: 3rem;
}

@media only screen and (max-width: 1200px) {
  #new-item {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  #list-container {
    padding: 0 1rem;
  }
}

@media only screen and (max-width: 600px) {
  #new-item {
    flex-direction: column;
    .select {
      border: none;
      border-radius: 0;
      border-top: 2px #f2f2f2 solid;
      margin: 0;
      padding: 1rem 0;
      select {
        text-align: left;
        width: 100%;
      }
    }
  }
}

/* custom checkbox  styles */
#list {
  .input-container {
    width: 100%;
    display: inline-block;
    position: relative;
    padding-left: 2.2rem;
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-sizing: border-box;
  }
  
  .input-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #f2f2f2;
  }
  
  .input-container:hover input ~ .checkmark {
    background-color: #d9d9d9;
  }
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .input-container .checkmark.box:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}