.plan-entry {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .controls {
    color: #aaa;
    font-size: 1.3rem;
  }
  .meta {
    color: #333;
    padding-top: .5rem;
    font-weight: 600;
    font-size: .9rem;
    text-transform: uppercase;
  }
  .title {
    color: #333;
    padding-top: .25rem;
    font-size: 1.1rem;
    max-width: 80vw;
  }
  a.title:hover {
    text-decoration: underline;
  }
}